import { Box, CssBaseline } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {  selectDocumentById } from './../envelope/envelopeSlice';
import DocumentDrawer from './DocumentDrawer';
import DocumentViewer from './DocumentViewer';

const Document = () => {
   const { docId } = useParams();
   const document = useSelector(state => selectDocumentById(state, docId));

   return (
      <Box sx={{ display: 'flex' }}>
         <CssBaseline />
         <DocumentDrawer document={document} />
         <Box sx={{ flexGrow: 1, bgColor: 'background.default', px: 3, pb: 3, pt: 2 }}>
            <DocumentViewer document={document} />
         </Box>
      </Box >
   );
};
export default Document;
