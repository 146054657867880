import { AuthenticatedTemplate } from '@azure/msal-react';
import { Chip, useMediaQuery } from '@mui/material';
import { Box, CssBaseline } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageTitle } from '../../../components/layout/PageTitle';
import Dashboard from './Dashboard';
import EnvelopeDrawer from './EnvelopeDrawer';
import { styled, useTheme } from '@mui/material/styles';
import { Outlet, Route, Routes, useParams } from 'react-router-dom';
import { fetchEnvelope } from './envelopeSlice';
import ProgressBackdrop from '../../../components/progress-backdrop/ProgressBackdrop';
import ConsentFormsList from './ConsentFormsList';
import { useState } from 'react';

const Envelope = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmallViewport = useMediaQuery(theme.breakpoints.down('lg'));

    const { id } = useParams();

    const envelope = useSelector((state) => state.envelope.entity);
    const loadingStatus = useSelector((state) => state.envelope.status);
    const [openDrawer, setOpenDrawer] = useState(false);

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    }

    useEffect(() => {
        // if (envelope && envelope.id == id)
        //     return;

        if (loadingStatus !== 'idle')
            return;

        (async () => await dispatch(fetchEnvelope(id)))();
    }, []);

    if (!envelope || loadingStatus !== 'idle') {
        return (
            <ProgressBackdrop open={true} />
        )
    }
    
    return (
        <AuthenticatedTemplate>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <EnvelopeDrawer isSmallViewport={isSmallViewport} open={openDrawer} toggleDrawer={toggleDrawer} />
                <Box sx={{ flexGrow: 1, bgColor: 'background.default', px: 3, pb: 3, pt: 2 }}>
                    <PageTitle title={envelope.title}  allowBack={true} showMenuIcon={isSmallViewport} menuClickHandler={toggleDrawer}
                        rightAction={<Chip variant="contained" color="info" label="Your Role: Signer" />}
                    />
                    <Box mt={3}>
                        <Outlet />
                    </Box>
                </Box>
            </Box >
        </AuthenticatedTemplate>
    );
};
export default Envelope;
