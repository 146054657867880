import { useMsal } from "@azure/msal-react";
import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from "react";

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    name = name === "unknown" ? "Unknown Unknown" : name.replace(/\s+/g, ' ').trim();
    name = name.split(' ');
    return {
       sx: {
          bgcolor: stringToColor(name.toString()),
       },
       children: `${name[0][0]}${name.length === 1 ? '' : name[1][0]}`,
    };
 }

const WelcomeName = () => {
    const { instance } = useMsal();
    const [name, setName] = useState(null);

    const activeAccount = instance.getActiveAccount();
    useEffect(() => {
        if (activeAccount) {
            setName(activeAccount.name);
        } else {
            setName(null);
        }
    }, [activeAccount]);

    if (name) {
        return <Avatar {...stringAvatar(name)} />;
    } else {
        return null;
    }
};

export default WelcomeName;